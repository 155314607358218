import React from 'react'
import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/solid'

import useFaq from '../hooks/useFaq'

const Support = () => {
    const classList = {
        button: "flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
    }

    const faqList = useFaq()
    return (
        <div className="container py-8 lg:py-16">
            <h1 className="text-2xl font-bold mb-8 text-center">Frequently Asked Questions</h1>
            <div className="max-w-xl mx-auto bg-white shadow-md rounded-md p-4 grid grid-cols-1 gap-y-2">
            {faqList.map((item) => {
                return (
                    <Disclosure>
                        {({ open }) => (
                            <div>
                            <Disclosure.Button className={classList.button}>
                                <span>{item.title}</span>
                                <ChevronUpIcon
                                className={`${
                                    open ? 'transform rotate-180' : ''
                                } w-5 h-5 text-gray-700`}
                                />
                            </Disclosure.Button>
                            <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">{item.description}</Disclosure.Panel>
                            </div>
                        )}
                    </Disclosure>
                )
            })}   
            </div>
            <a className="block w-full text-center max-w-xl mx-auto mt-8" href="mailto:support@ativoai.com">If you have any questions, please contact us at <span className="font-bold">support@ativoai.com</span></a>
        </div>
    )
}

export default Support