const useNavigation = () => {
    return [
        { 
            name: 'Billing', 
            href: '/billing' 
        },
        { 
            name: 'Sign Out', 
            href: '/callback?type=logout' 
        },
    ]
}

export default useNavigation