import React from 'react'
import { Link } from 'gatsby'
import { useParams } from '@reach/router'
import { 
    Formik, 
    Form,
    Field,
} from 'formik'
import { useQueryClient } from 'react-query'

import {
    useNotifications
} from '../../contexts/notifications'

import {
    useCampaign,
    useCampaignEmailMutation,
    useCampaignTitleMutation
} from '../../api/index'

import RichTextEditor from '../../components/richTextEditor'

const EditForm = ({
    initialValues,
    campaignId,
    templateId
}) => {
    const {
        dispatch
    } = useNotifications()

    const queryClient = useQueryClient()

    const campaignEmailMutatation = useCampaignEmailMutation()

    const handleSubmit = async ( data, { setSubmitting } ) => {
        setSubmitting(true)

        try {
            // const inputFields = [
            //     ...data.followUps.map((value, index) => ({
            //         name: `Follow Up ${index + 1} Body`,
            //         value
            //     })),
            //     {
            //         name: `Initial Email Body`,
            //         value: data.content
            //     },
            //     {
            //         name: `Initial Email Subject`,
            //         value: data.subject
            //     }
            // ]

            // inputFields.forEach(({
            //     name,
            //     value
            // }) => {
            //     if (value.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
            //         throw new Error(`Field required: ${name}`)
            //     }
            // })

            await campaignEmailMutatation.mutateAsync({
                content: data.content,
                id: campaignId,
                templateId,
                follow_ups: data.followUps,
                subject: data.subject
            })

            queryClient.invalidateQueries()

            setSubmitting(false)

            dispatch({
                type: "ADD_SUCCESS",
                payload: "Campaign successfully updated"
            })
        } catch (e) {
            dispatch({
                type: "ADD_ERROR",
                payload: e.message
            })

            setSubmitting(false)
        }
    } 
    return (
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                {({
                    isSubmitting,
                    values,
                    setFieldValue
                }) => (
                    <Form>
                        <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                            <div>
                                <div>
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">Initial Email</h3>
                                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                        This email will be the first email sent out during your campaign.
                                    </p>
                                </div>
                                <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                            Subject
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            <RichTextEditor 
                                                id={'subject'} 
                                                disabled={isSubmitting} 
                                                value={values['subject']} 
                                                setValue={(input) => setFieldValue('subject', input)}
                                                singleLine
                                                maxLength={78}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                            Body
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            <RichTextEditor 
                                                id={'content'} 
                                                disabled={isSubmitting} 
                                                value={values['content']} 
                                                setValue={(input) => setFieldValue('content', input)}
                                                size='large'
                                                link
                                                html
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {values.followUps.map((value, index) => {
                                return (
                                <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5" key={index}>
                                    <div>
                                        <div>
                                            <h3 className="text-lg leading-6 font-medium text-gray-900">Follow Up {index + 1}</h3>
                                            <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                                This email will be the {index === 0 ? 'first' : index === 1 ? 'second' : 'third'} email sent out during your campaign.
                                            </p>
                                        </div>
                                        <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                                <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                    Body
                                                </label>
                                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                    <RichTextEditor 
                                                        id={`followup-content-${index}`} 
                                                        disabled={isSubmitting} 
                                                        value={values['followUps'][index]} 
                                                        setValue={(input) => setFieldValue(`followUps.${index}`, input)}
                                                        size='large'
                                                        link
                                                        html
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )
                            })}
                        </div>
                        <div className="pt-5">
                            <div className="flex justify-end">
                                <Link
                                    to="/app"
                                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                                >
                                    Cancel
                                </Link>
                                <button
                                    type="submit"
                                    className="ml-3 w-20 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-black hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                                >
                                    {isSubmitting ?
                                        <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                            <circle cx="50" cy="50" fill="none" stroke="currentColor" strokeWidth="10" r="35" strokeDasharray="164.93361431346415 56.97787143782138">
                                                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"/>
                                            </circle>
                                        </svg>
                                    :
                                        `Update`
                                    }
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>   
    )
}

const EditTitle = ({
    initialValues,
    campaignId
}) => {
    const {
        dispatch
    } = useNotifications()

    const queryClient = useQueryClient()

    const campaignTitleMutation = useCampaignTitleMutation()

    const handleSubmit = async ( data, { setSubmitting } ) => {
        setSubmitting(true)

        try {
            await campaignTitleMutation.mutateAsync({
                title: data.title,
                id: campaignId,
            })

            queryClient.invalidateQueries()

            setSubmitting(false)

            dispatch({
                type: "ADD_SUCCESS",
                payload: "Campaign title updated"
            })
        } catch (e) {
            dispatch({
                type: "ADD_ERROR",
                payload: e.message
            })

            setSubmitting(false)
        }
    } 

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
        >
            {({
                isSubmitting,
                values,
                submitForm
            }) => (
                <Form>
                    <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                        <div>
                            <div>
                                <h3 className="text-lg leading-6 font-medium text-gray-900">Details</h3>
                                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                    Change the basic details of your campaign.
                                </p>
                            </div>
                            <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label htmlFor="title" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        Title
                                    </label>
                                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                                        <Field
                                            id="title"
                                            name="title"
                                            type="text"
                                            onBlur={async () => {
                                                if ( values['title'] && initialValues.title !== values['title'] ) await submitForm()
                                            }}
                                            autoComplete="title"
                                            disabled={isSubmitting}
                                            required
                                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-sm"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

const EditCampaign = () => {
    const { campaignId } = useParams()

    const campaignQuery = useCampaign({
        campaignId
    })

    return (
        <div className="container py-8 lg:py-16">
            <h1 className="text-2xl font-bold mb-8">Edit Campaign</h1>
            <div className="bg-white shadow-md rounded overflow-hidden p-4 lg:p-8">
                <div className="space-y-8 divide-y divide-gray-200">
                    {campaignQuery.isLoading ? 
                        <>
                            <div className="mb-4">
                                <div className="leading-relaxed w-16 h-3 animate-pulse bg-gray-400 mb-2"></div>
                                <div className="leading-relaxed w-full h-7 animate-pulse bg-gray-400"></div>
                            </div>
                            <div className="mb-4 pt-8">
                                <div className="leading-relaxed w-16 h-3 animate-pulse bg-gray-400 mb-2"></div>
                                <div className="leading-relaxed w-full h-7 animate-pulse bg-gray-400"></div>
                            </div>
                            <div className="mb-4 pt-8">
                                <div className="leading-relaxed w-16 h-3 animate-pulse bg-gray-400 mb-2"></div>
                                <div className="leading-relaxed w-full h-7 animate-pulse bg-gray-400"></div>
                            </div>
                            <div className="mb-4 pt-8">
                                <div className="leading-relaxed w-16 h-3 animate-pulse bg-gray-400 mb-2"></div>
                                <div className="leading-relaxed w-full h-7 animate-pulse bg-gray-400"></div>
                            </div>
                        </>
                    : campaignQuery.data ?
                        <>
                            <EditTitle
                                initialValues={{
                                    title: campaignQuery.data.title,
                                }}
                                campaignId={campaignId}
                            />
                            <EditForm
                                initialValues={{
                                    content: campaignQuery.data.template.content,
                                    followUps: campaignQuery.data.template.follow_ups.length > 0 ? campaignQuery.data.template.follow_ups : ["", "", ""],
                                    subject: campaignQuery.data.template.subject
                                }}
                                campaignId={campaignId}
                                templateId={campaignQuery.data.template.id}
                            />
                        </>
                    : 'Campaign does not exist'}
                </div>
            </div>
        </div>
    )
}

export default EditCampaign