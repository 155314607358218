import { useMutation, useQueryClient } from 'react-query'
import { gql } from 'graphql-request'

import {
    useGraphqlClient
} from '../index'

const useCampaignEmailMutation = () => {
    const queryClient = useQueryClient()

    const graphqlClient = useGraphqlClient()

    const { isLoading, data, error, mutateAsync } = useMutation(async ({
        content,
        templateId,
        follow_ups,
        id,
        subject
    }) => {
        const query = gql`
            mutation updateCampaign( $templateId: Int!, $follow_ups: [String!]!, $content: String!, $subject: String! ) {
                updateTemplateById(
                    content: $content,
                    template_id: $templateId,
                    follow_ups: $follow_ups,
                    subject: $subject
                )
            }
        `
        
        const variables = {
            templateId,
            follow_ups,
            content,
            subject
        }
        
        const { update_campaigns_by_pk } = await graphqlClient.request(query, variables)
        
        return update_campaigns_by_pk
    }, {
        retry: false,
        staleTime: 0
    })

    queryClient.invalidateQueries()

    return { isLoading, data, error, mutateAsync }
}

export default useCampaignEmailMutation