import { GraphQLClient } from 'graphql-request'
import { useAuth } from '../contexts/auth'

// Queries
import useStripeClient from './queries/useStripeClient'
import useCampaign from './queries/useCampaign'
import useCampaigns from './queries/useCampaigns'
import useRecentCampaign from './queries/useRecentCampaign'
import useCampaignSearch from './queries/useCampaignSearch'
import useStripeBillingSession from './queries/useStripeBillingSession'
import useStripeCheckoutSession from './queries/useStripeCheckoutSession'

// Mutations
import useCampaignEmailMutation from './mutations/useCampaignEmailMutation'
import useCampaignTitleMutation from './mutations/useCampaignTitleMutation'

export {
    // Stripe
    useStripeBillingSession,
    useStripeCheckoutSession,
    useStripeClient,
    // Campaigns
    useCampaign,
    useRecentCampaign,
    useCampaigns,
    useCampaignSearch,
    useCampaignEmailMutation,
    useCampaignTitleMutation
}

export const useGraphqlClient = () => {
    const {
        authState
    } = useAuth()

    const options = {
        headers: {
            'Authorization': `Bearer ${authState.token}`
        }
    }
    
    const graphqlClient = new GraphQLClient( process.env.GATSBY_GRAPHQL_URL, options )

    return graphqlClient
}