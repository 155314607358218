import React, { Fragment } from 'react'
import { Link } from 'gatsby'
import { 
    Menu, 
    Transition 
} from '@headlessui/react'
import { 
    UserCircleIcon,
    ChevronDownIcon
} from '@heroicons/react/solid'

import useUserNavigation from '../hooks/useUserNavigation'
import useProfile from '../hooks/useProfile'

const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ')
}

const Header = () => {
    const userNavigation = useUserNavigation()

    const profile = useProfile()

    return (
        <header className="flex bg-white h-16 w-full sticky top-0 border-b z-20 px-6 items-center justify-between">
            <div className="flex items-center">
                <Link className="flex items-center font-bold text-2xl leading-none" to="/app">
                    Ativo AI
                </Link>
            </div>
            <div className="ml-auto flex items-center md:ml-6">
                <Menu as="div" className="relative">
                {({ open }) => (
                    <>
                    <div>
                        <Menu.Button className="max-w-xs bg-white rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 lg:p-2 lg:rounded-md lg:hover:bg-gray-50">
                            {profile && profile.photoURL ? 
                                    <img
                                        className="h-8 w-8 rounded-full"
                                        src={profile.photoURL}
                                        alt=""
                                    />
                                :
                                    <UserCircleIcon className="h-8 w-8 rounded-full" />
                                }
                            <span className="hidden ml-3 text-gray-700 text-sm font-medium lg:block">
                                <span className="sr-only">Open user menu for </span>{profile ? profile.displayName : 'Guest'}
                            </span>
                            <ChevronDownIcon
                                className="hidden flex-shrink-0 ml-1 h-5 w-5 text-gray-400 lg:block"
                                aria-hidden="true"
                            />
                        </Menu.Button>
                    </div>
                    <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items
                            static
                            className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                        {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                            {({ active }) => (
                                <Link
                                    to={`/app${item.href}`}
                                    className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'block px-4 py-2 text-sm text-gray-700'
                                )}
                                >
                                {item.name}
                                </Link>
                            )}
                            </Menu.Item>
                        ))}
                        </Menu.Items>
                    </Transition>
                    </>
                )}
                </Menu>
            </div>
        </header>
    )
}

export default Header