import { useQuery, useQueryClient } from 'react-query'
import { gql } from 'graphql-request'

import {
    useGraphqlClient
} from '../index'

const useStripeBillingSession = () => {
    const queryClient = useQueryClient()
    
    const graphqlClient = useGraphqlClient()

    const { isLoading, data, error } = useQuery(`stripe-billing-session`, async () => {
        const query = gql`
            mutation StripeBillingSession {
                createStripeBillingPortalSession
            }
        `

        const { createStripeBillingPortalSession } = await graphqlClient.request(query)

        return createStripeBillingPortalSession
    }, {
        retry: false,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
    })

    queryClient.invalidateQueries('campaigns')

    return { isLoading, data, error }
}

export default useStripeBillingSession