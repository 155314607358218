import { useQuery } from 'react-query'
import { gql } from 'graphql-request'

import {
    useGraphqlClient
} from '../index'

const useCampaign = ({
    campaignId
}) => {
    const graphqlClient = useGraphqlClient()

    const { isLoading, data, error, isFetching } = useQuery(`campaign-${campaignId}`, async () => {
        const query = gql`
            query campaignQuery($campaignId: String!) {
                campaigns_by_pk(id: $campaignId) {
                    id
                    title
                    price_id
                    updated_at
                    status
                    template {
                        id
                        content
                        follow_ups
                        subject
                    }
                }
            }
        `

        const variables = {
            campaignId,
        }
                
        const { campaigns_by_pk } = await graphqlClient.request(query, variables)

        return campaigns_by_pk
    }, {
        retry: false,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
    })

    return { isLoading, data, error, isFetching }
}

export default useCampaign