import {
    useAuth
} from '../contexts/auth'

const useProfile = () => {
    const {
        authState
    } = useAuth()

    return authState.user
}

export default useProfile