import { useQuery, useQueryClient } from 'react-query'
import { gql } from 'graphql-request'

import {
    useGraphqlClient
} from '../index'

const useStripeCheckoutSession = ({
    priceId
}) => {
    const queryClient = useQueryClient()

    const graphqlClient = useGraphqlClient()

    const { isLoading, data, error } = useQuery(`stripe-checkout-session`, async () => {
        const query = gql`
            mutation StripeCheckoutSession($priceId: String!) {
                createStripeCheckoutSession( priceId: $priceId )
            }
        `

        const variables = {
            priceId,
        }
        
        const { createStripeCheckoutSession } = await graphqlClient.request(query, variables)

        return createStripeCheckoutSession
    }, {
        retry: false,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
    })

    queryClient.invalidateQueries('campaigns')

    return { isLoading, data, error }
}

export default useStripeCheckoutSession