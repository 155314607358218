import { useMutation, useQueryClient } from 'react-query'
import { gql } from 'graphql-request'

import {
    useGraphqlClient
} from '../index'

const useCampaignTitleMutation = () => {
    const queryClient = useQueryClient()

    const graphqlClient = useGraphqlClient()

    const { isLoading, data, error, mutateAsync } = useMutation(async ({
        title,
        id,
    }) => {
        const query = gql`
            mutation updateCampaign($title: String!, $id: String!) {
                update_campaigns_by_pk(
                    pk_columns: {
                        id: $id
                    },
                    _set: {
                        title: $title,
                    }
                ) {
                    title,
                }
            }
        `
        
        const variables = {
            title,
            id
        }
        
        const { update_campaigns_by_pk } = await graphqlClient.request(query, variables)
        
        return update_campaigns_by_pk
    }, {
        retry: false,
        staleTime: 0
    })

    queryClient.invalidateQueries()

    return { isLoading, data, error, mutateAsync }
}

export default useCampaignTitleMutation