import React, { useEffect } from 'react'
import { navigate } from 'gatsby'

import Loading from '../components/loading'

import {
    useStripeBillingSession
} from '../api/index'

const Billing = () => {
    const { data, error } = useStripeBillingSession()

    useEffect(() => {
        if ( error ) return navigate('/app') 

        if ( data ) {
            window.location.href = data
        }
    }, [ data, error ])

    return <Loading/>
}

export default Billing