import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import queryString from 'query-string'
import { useQueryClient } from 'react-query'

import Loading from '../components/loading'

import isBrowser from '../validators/isBrowser'

import {
    useAuth
} from '../contexts/auth'

const Callback = ({ location }) => {
    const {
        authState,
        authService
    } = useAuth()

    const queryClient = useQueryClient()

    useEffect(() => {
        if ( !isBrowser() ) return

        queryClient.invalidateQueries()

        if (location.search) {
            const { type } = queryString.parse(location.search)
            
            if (!type) return

            switch (type) {
                case 'logout':
                    authService.logout()
                    return navigate('/')
                default:
                    return navigate('/app')
            }
        }

        return navigate('/app')
    }, [ authState, authService, location.search, queryClient ])

    return <Loading/>
}

export default Callback