import React from 'react'

const NotFound = () => {
    return (
        <div className="container py-8 pt-8 lg:py-16">
            <h1 className="text-2xl font-bold mb-8">Page not found</h1>
        </div>
    )
}

export default NotFound