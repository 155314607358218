import { useQuery } from 'react-query'
import { gql } from 'graphql-request'

import {
    useGraphqlClient
} from '../index'

const useCampaigns = () => {
    const graphqlClient = useGraphqlClient()

    const { isLoading, data, error } = useQuery(`campaigns`, async () => {
        const query = gql`
            query campaignQuery {
                campaigns(order_by: {created_at: desc}) {
                    id
                    title
                    price_id
                    updated_at
                    status
                    report {
                        stats {
                            opens
                            clicks
                            bounces
                            receipients
                        }
                    }
                }
            }
        `
        
        const { campaigns } = await graphqlClient.request(query)

        return campaigns
    }, {
        retry: false,
        refetchOnMount: true,
        staleTime: 0
    })

    return { isLoading, data, error }
}

export default useCampaigns