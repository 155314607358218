import React, { useState } from 'react'
import { Router } from '@reach/router'

import Campaigns from '../templates/campaigns/index'
import EditCampaign from '../templates/campaigns/edit'
import Callback from '../templates/callback'
import Checkout from '../templates/checkout'
import Billing from '../templates/billing'
import Support from '../templates/support'
import NotFound from '../templates/notFound'

import AuthComponent from '../components/authComponent'
import Layout from '../components/layout'
import Header from '../components/header2'

const App = ({
    location,
    ...rest
}) => {
    const [ sidebarOpen, setSidebarOpen ] = useState(false)

    return (
        <AuthComponent 
            isProtected
        >
            <div className="min-h-screen flex flex-wrap bg-gray-50">
                <Header
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                />
                <div className="flex w-full">
                    <Layout>
                        <Router basepath="/app">
                            <Campaigns path="/"/>
                            <EditCampaign path="/campaigns/edit/:campaignId"/>
                            <Billing path="/billing"/>
                            <Checkout path="/checkout"/>
                            <Callback path="/callback"/>
                            <Support path="/support"/>
                            <NotFound default/>
                        </Router>
                    </Layout>
                </div>
            </div>
        </AuthComponent>
    )
}

export default App