import React from 'react'
import classNames from 'classnames'
import { 
    PencilIcon
} from '@heroicons/react/solid'
import { Link } from 'gatsby'

import {
    useCampaigns
} from '../../api/index'

const Loading = () => {
    return (
        <li>
            <div className="block">
                <div className="px-4 py-4 sm:px-6">
                    <div className="flex items-center justify-between">
                        <div className="leading-relaxed w-32 h-5 animate-pulse bg-gray-400"></div>
                        <div className="ml-2 flex-shrink-0 flex">
                            <div className="leading-relaxed w-14 h-5 animate-pulse bg-gray-400 rounded-full"></div>
                        </div>
                    </div>
                    <div className="mt-2 sm:flex sm:justify-between">
                        <div className="sm:flex">
                            <div className="leading-relaxed w-24 h-4 animate-pulse bg-gray-400 mr-2"></div>
                            <div className="leading-relaxed w-24 h-4 animate-pulse bg-gray-400"></div>
                        </div>
                        <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                            <div className="leading-relaxed w-32 h-4 animate-pulse bg-gray-400"></div>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    )
}

const Item = ({
    item,
}) => {
    const {
        report
    } = item

    const statClass = classNames(
        "lg:ml-6 text-center p-2 lg:p-0 bg-white lg:bg-transparent"
    )
    const statTitle = classNames(
        "font-medium text-sm lg:text-lg"
    )

    return (
        <div className="block lg:hover:bg-gray-50 relative">
            <div className="flex flex-wrap lg:flex-nowrap lg:items-center justify-between px-4 py-6">
                <div className="flex max-w-xs w-full lg:w-max mx-auto mb-4 lg:mb-0 lg:mx-0">
                    <h2 className="text-center lg:text-left w-full lg:w-auto truncate font-bold lg:font-normal">{item.title}</h2>
                </div>
                <div className="w-full lg:w-max grid bg-gray-300 lg:bg-transparent grid-cols-2 gap-px lg:gap-4 lg:flex lg:gap-0">
                    <div className={statClass}>
                        <h3>Prospects Reached</h3>
                        <p className={statTitle}>{report ? report.stats.receipients : `-`}</p>
                    </div>
                    <div className={statClass}>
                        <h3>Opens</h3>
                        <p className={statTitle}>{report ? report.stats.opens : `-`}</p>
                    </div>
                    <div className={statClass}>
                        <h3>Open Rate</h3>
                        <p className={statTitle}>{report ? `${(report.stats.opens / report.stats.receipients * 100).toFixed(2)}%` : `-`}</p>
                    </div>
                    <div className={statClass}>
                        <h3>Clicks</h3>
                        <p className={statTitle}>{report ? report.stats.clicks : `-`}</p>
                    </div>
                    <div className={statClass}>
                        <h3>Click Rate</h3>
                        <p className={statTitle}>{report ? `${(report.stats.clicks / report.stats.receipients * 100).toFixed(2)}%` : `-`}</p>
                    </div>
                    <div className="lg:hidden bg-white"></div>
                </div>
                <Link to={`/app/campaigns/edit/${item.id}`} className="lg:ml-4 hidden lg:flex">
                    <PencilIcon
                        className="h-6 w-6 text-black"
                    />
                </Link>
            </div>
            <Link to={`/app/campaigns/edit/${item.id}`} className="absolute top-0 left-0 h-full w-full"></Link>
        </div>
    )
}

const Campaigns = ({ location }) => {
    const campaignQuery = useCampaigns()

    return (
        <div className="container py-8 lg:py-16">
            <h1 className="text-2xl font-bold mb-8">Campaigns</h1>
            {campaignQuery.isLoading ?
                <ul className="divide-y divide-gray-200 bg-white shadow overflow-hidden sm:rounded-md">
                    <Loading/>
                </ul>
                :
                <>
                    {campaignQuery.data && campaignQuery.data.length > 0 &&
                        <ul className="divide-y divide-gray-200 bg-white shadow overflow-hidden sm:rounded-md">
                            {campaignQuery.data.map((campaign) => (
                                <li key={campaign.id}>
                                    <Item
                                        item={campaign}
                                    />
                                </li>
                            ))}
                        </ul>
                    }
                    {(!campaignQuery.data || campaignQuery.data.length === 0 || campaignQuery.error) &&
                        <div className="">
                            <p className="mb-4">You don't have any campaigns</p>
                            <Link className="py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-black hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black" to={`/app/checkout?priceId=${process.env.GATSBY_STRIPE_PRICE_ID}`}>Sign up</Link>
                        </div>
                    }
                </>
            }
        </div>
    )
}

export default Campaigns