import React, { useEffect } from 'react'
import queryString from 'query-string'
import { navigate } from 'gatsby'

import Loading from '../components/loading'

import {
    useStripeCheckoutSession,
    useStripeClient
} from '../api/index'

const Checkout = ({
    location
}) => {
    const { priceId } = queryString.parse(location.search)

    const sessionQuery = useStripeCheckoutSession({
        priceId
    })

    const stripeQuery = useStripeClient()

    useEffect(() => {
        if ( sessionQuery.error || stripeQuery.error ) {
            return navigate('/app') 
        }

        if ( sessionQuery.data && stripeQuery.data ) {
            const handleRedirect = async () => {
                await stripeQuery.data.redirectToCheckout({
                    sessionId: sessionQuery.data
                })
            }

            handleRedirect()
        }
    }, [ sessionQuery, stripeQuery ])

    return <Loading/>
}

export default Checkout