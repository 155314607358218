const useProfile = () => {
    const faqList = [
        {
            title: "What is Ativo AI",
            description: "Ativo AI is a software that coaches and consultants use to get more customers with automated prospecting and highly personalized email outreach. With an Ativo AI account, users can customize their messaging and targeting, and start or stop their campaigns at any time."
        }, {
            title: "How does Ativo AI work?",
            description: "Ativo AI is a software that coaches and consultants use to get more customers with automated prospecting and highly personalized email outreach. With an Ativo AI account, users can customize their messaging and targeting, and start or stop their campaigns at any time."
        }, {
            title: "How much does Ativo AI cost?",
            description: "Visit our pricing page for more details."
        }, {
            title: "I don’t have much time. Will Ativo AI work for me?",
            description: "Ativo AI is designed to be low maintenance. Our software and team of experts will completely automate your prospecting and outreach, so you can focus on driving your business forward."
        }, {
            title: "Can I expect my campaigns to be successful overnight?",
            description: "Probably not — it takes time to see which outreach copy works best. To help, Ativo AI’s smart campaigns use technology to monitor and improve your campaigns automatically as you go. With smart campaigns, businesses usually hit their stride in the first month, so we recommend you budget at least that amount of time for best results. Your exact ramp up time may be shorter or longer than that depending on various factors, like your email copy and the amount of competition in your niche."
        }
    ]

    return faqList
}

export default useProfile