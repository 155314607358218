import React, { useEffect, useState } from 'react'
import { useQuill } from 'react-quilljs'
import '../styles/quil.css'

import isBrowser from '../validators/isBrowser'

const Editor = ({
    value,
    setValue,
    modules,
    formats,
    maxLength,
    html,
    className
}) => {
    const [ initialMount, setInititalMount ] = useState(true)

    const { quill, quillRef, Quill } = useQuill({
        modules,
        formats,
    })

    if ( Quill && !quill ) {
        Quill.imports['ui/icons']['link'] = null
    }

    useEffect(() => {
        if ( !isBrowser() || !quill ) return

        if ( initialMount ) {
            quill.setContents( quill.clipboard.convert(value) )

            quill.on('text-change', (delta, oldDelta, source) => {
                if ( maxLength ) {
                    const curLength = quill.getLength()

                    if ( curLength > maxLength ) {
                        quill.deleteText( maxLength, curLength )
                    }
                }

                if ( html ) {
                    return setValue( quill.root.innerHTML )
                }

                return setValue( quill.getText() )
            })

            setInititalMount(false)
        }
    }, [ quill, value, setValue, initialMount, setInititalMount, maxLength, html ])

    return (
        <>
            <div ref={quillRef} className={className}/>
            {maxLength && <p className="mt-2 text-sm text-gray-500">{(value.length ? value.trim().length : 0)} / {maxLength}</p>}
        </>
    )
}

const CustomToolbar = ({
    id,
    link
}) => {
    const buttonClass = "flex bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold rounded px-2 mr-2 last:mr-0 h-6 items-center text-sm"
    
    return (
        <div id={id} className="flex flex-wrap items-center mb-2">
            {link && <button type="button" className={`ql-link ${buttonClass}`}>
                <span>Link</span>
            </button>}
            <button type="button" className={`ql-insertUsername ${buttonClass}`}>
                <span>Username</span>
            </button>
            <button type="button" className={`ql-insertEmail ${buttonClass}`}>
                <span>Email</span>
            </button>
        </div>
    )
}

function insertUsername() {
    if ( !isBrowser() || !this.quill ) return

    if ( !this.quill.getSelection() ) {
        return
    }

    const cursorPosition = this.quill.getSelection().index

    const payload = "{username}"

    this.quill.insertText(cursorPosition, payload)

    this.quill.setSelection(cursorPosition + payload.length)
}

function insertEmail() {
    if ( !isBrowser() || !this.quill ) return

    if ( !this.quill.getSelection() ) {
        return
    }

    const cursorPosition = this.quill.getSelection().index

    const payload = "{email}"

    this.quill.insertText(cursorPosition, payload)

    this.quill.setSelection(cursorPosition + payload.length)
}

const RichEditor = ({
    value,
    setValue,
    id,
    link,
    singleLine,
    maxLength,
    size,
    html
}) => {
    const componentId = `editor-${id}`

    const modules = {
        toolbar: {
            container: `#${componentId}`,
            handlers: {
                insertUsername,
                insertEmail
            }
        },
        keyboard: {
            bindings: {},
        },
    }

    if (singleLine) {
        modules.keyboard.bindings = {
            ...modules.keyboard.bindings,
            handleEnter: {
                key: 13,
                handler: function() {},
            },
            "header enter": {
                key: 13,
                handler: function() {},
            },
        }
    }

    return (
        <>
            <CustomToolbar id={componentId} link={link}/>
            <Editor
                value={value}
                setValue={setValue}
                modules={modules}
                className={size === 'large' ? 'quill_field-large' : ''}
                maxLength={maxLength}
                html={html}
                formats={['link']} 
            />
        </>
    )
}
export default RichEditor